<template>
    <div>
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            <!-- Mask -->
            
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">

                <div class="col-lg-7 p-0 col-md-10">
                    <h3 class="mb-0 text-white">Úrovne hostí</h3>
                    <h2 class="" v-if="role.title">{{role.title}}</h2>
                    <h2 class="" v-else>Nová úroveň</h2>
                </div>
            </div>
        </div>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-6 order-xl-1">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Nastavenia</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                            </div>
                        </div>
                        <template>
                            <form @submit.prevent="handleSubmit">
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <a-input alternative=""
                                                        label="Názov úrovne"
                                                        input-classes="form-control-alternative"
                                                        v-model="role.title"
                                            />
                                        </div>
                                        <div class="col-lg-12">
                                            <a-input alternative=""
                                                        label="Krátky názov úrovne"
                                                        input-classes="form-control-alternative"
                                                        v-model="role.short"
                                            />
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group has-label">
                                                <label class="form-control-label">Popis úrovne</label>
                                                <a-textarea placeholder="Popis úrovne pre hosťa" class="form-control form-control-alternative" auto-size v-model="role.gdescription"/>
                                             </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group has-label">
                                                <label class="form-control-label">Od počtu nocí</label>
                                                <a-input-number size="large" :min="1" :max="100000" :default-value="3" class="form-control form-control-alternative"  v-model="role.glimit" />
                                            </div>

                                        </div>
                                        <div class="col-lg-11">
                                            <div class="form-group has-label">
                                                <label class="form-control-label">Zľava úrovne</label>
                                                <a-input-number size="large" :min="0" :max="10" :step="0.1" class="form-control form-control-alternative" v-model="role.discount" />
                                            </div>
                                        </div>
                                        <div class="col-lg-1">
                                            <div class="form-group has-label">
                                                <label class="form-control-label"></label>
                                                %
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <button class="btn btn-primary">Save</button>
                                </div>

                            </form>
                        </template>
                    </a-card>
                </div>
                <div class="col-xl-6 order-xl-1">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Štatistika</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                            </div>
                        </div>
                        <template>
                        </template>
                    </a-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {API_URL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'

    Vue.use(FormItem);


    export default {
        name: 'level',
        props: ['id'],

        data() {
            return {
                head: authHeader(),
                send: API_URL,
                permissions: [],
                colorsto: JSON.parse(localStorage.colors),
                formItemLayout: {
                    labelCol: {span: 6},
                    wrapperCol: {span: 14},
                },
                roleId: {
                    uid: this.id
                },
                role: {
                    title: '',
                },
            }
        },
        methods: {
            handleSubmit() {
                let $role_data = this.role;
                if(this.id !== "add")
                    $role_data["id"] = this.id;
                dataService.axiosPost(this.$options.name, $role_data).then(results => {
                    if (results.data.message) {
                        this.$router.push("/" + this.$options.name + "/" + results.data.message);
                    }
                });
            },
        },
        mounted() {
            if (!isNaN(this.id)) {
                dataService.axiosFetch(this.$options.name, this.id).then(results => {
                    this.role = results;
                });
            }
        }
    };
</script>
<style>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }
    .ant-checkbox-group-item
    {display:inline-block;width:100%; margin-bottom:10px;}
</style>
